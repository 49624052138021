import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch`}</h2>
    <p>{`Say `}<a href="mailto:muresanandrei@icloud.com">{`Hi`}</a>{` or find me on other platforms: `}</p>
    <a href="https://www.linkedin.com/in/andreimuresanw">Linkedin</a> 

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      