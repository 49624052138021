import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Buildingit" link="https://buildingit.com" bg="linear-gradient(66deg, rgba(77,148,218,1) 75%, rgba(232,234,238,1) 100%)" mdxType="ProjectCard">
 Buildingit is the fastest way to find new jobs if you’re in the market and to locate potential team members to work on jobs you might already be on. 
 Post, search for and bid on thousands of new projects by location or by area of expertise.
    </ProjectCard>
    <ProjectCard title="Stoovo" link="https://stoovo.com" bg="linear-gradient(0deg, #403bdd, #403bdd),
    linear-gradient(
      180deg,
      #6662ff 0%,
      #4b47fc 30.59%,
      #3b38d9 62.56%,
      #2c2ab1 80.01%,
      #3532e1 80.36%,
      #fff 83.4%,
      #fff 100%
    )" mdxType="ProjectCard">
   Manage your bills and work off your purchase with short-term gigs. Stoovo it's a personal finance app looking out for you.
    </ProjectCard>
    <ProjectCard title="Teleskop" link="https://teleskop.ro" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  Telescope is a tool that helps teachers to generate 100% anonymous feedback after classes. Simple and fast, 100% helpful.
    </ProjectCard>
    <ProjectCard title="Webremarks" link="https://webremarks.com" bg="linear-gradient(to right, #c24260 20%, #871ea0 100%)" mdxType="ProjectCard">
 Experience the most cutting-edge screenshot software, for free in the form of native macOS and Windows apps.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      