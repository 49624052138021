import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`I’m a senior software engineer currently leading the web team at softhaus, a distributed team of freelancers from Europe and the US.`}</p>
    </blockquote>
    <p>{`With over 10 years of experience, I specialize in web and app development using PHP (Laravel), Node.js, GO, or newer technologies such as React and Gatsby. In my free time, I have developed & maintained React Native apps, complex web apps using AWS ec2 instances and Docker, Linux servers, and scalable API's.`}</p>
    <br />
    <code>
You can get in touch with me by sending an email to <a href="mailto:muresanandrei@icloud.com">muresanandrei@icloud.com</a> or <br />
calling me at <a href="tel:+40758624108">+40 758 624 108.</a>
    </code>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      